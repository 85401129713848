const BASE_URL = `${process.env.REACT_APP_API_URL}` || "https://app-preproject-dev.azurewebsites.net"; 

let access_token = ''

let TOKEN_HEADER = (): HeadersInit => ({
    'Authorization': `Bearer ${access_token}`
})

export const get = async (url: string) => {
    return await fetch(`${BASE_URL}/${url}`, {
        method: "GET"
    })
        .then(response => response.json())
}

export const getV2 = async (url: string) => {
    return await fetch(`${BASE_URL}/${url}`, {
        method: "GET"
    })
}

export const post = async (url: string, data?: any) => {
    return await fetch(`${BASE_URL}/${url}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
}

export const put = async (url: string, data?: any) => {
    return await fetch(`${BASE_URL}/${url}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
};

export const remove = async (url: string, data?: any) => {
    return await fetch(`${BASE_URL}/${url}`, {
        method: "DELETE"
    });
};

export const setUserToken = (accessToken: any) => {
    access_token = accessToken
}

